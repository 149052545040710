var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.dataSms,"headers":_vm.headerSMS,"loading":_vm.loading,"footer-props":{itemsPerPageText: 'Mostrar'},"search":_vm.search,"sort-by":"fecha_hora","sort-desc":""},on:{"click:row":function (item) { _vm.sendDetailsMessage(item)}},scopedSlots:_vm._u([{key:"header.status_code",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{attrs:{"small":"","color":"blue-grey"},domProps:{"textContent":_vm._s('mdi-comment-text-outline')}})]}},{key:"item.status_code",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.status_code === '200' ? 'icono' : 'red'},domProps:{"textContent":_vm._s(item.status_code === '200' ? 'mdi-check-all' : 'mdi-close')}})]}},{key:"item.co_cli",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(item.co_cli)}})]}},{key:"item.nombre_cliente",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",staticStyle:{"max-width":"19vw","width":"100%"}},[_c('v-list-item-avatar',{attrs:{"size":"30"}},[_c('v-icon',{staticClass:"blue",attrs:{"dark":""}},[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text font-weight-medium"},[_vm._v(" "+_vm._s(item.nombre_cliente)+" ")]),_c('v-list-item-subtitle',{staticClass:"font-italic",domProps:{"textContent":_vm._s(item.destinatario)}})],1),_c('v-list-item-action',{staticClass:"d-flex align-start ma-0 pa-0"},[_c('v-chip',{staticClass:"ma-1 px-1 font-weight-bold",attrs:{"color":"info","outlined":"","label":"","x-small":""}},[_vm._v(" +4 ")])],1)],1)]}},{key:"item.destinatario",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(item.destinatario)+" ")])]}},{key:"item.mensaje",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-sms text-truncate"},[_vm._v(" "+_vm._s(item.mensaje)+" ")])]}},{key:"item.fecha_hora",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('span',{staticClass:"font-weight-regular font-italic"},[_vm._v(" "+_vm._s(_vm._f("dateSMS")(item.fecha_hora))+" ")])])]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('div',{staticClass:"text-center pa-4"},[_c('span',{staticClass:"blue-grey--text font-italic"},[_vm._v("No se encontraron Mensjes Registrados")])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }