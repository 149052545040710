<template>
  <v-data-table
    :items="dataSms"
    :headers="headerSMS"
    :loading="loading"
    :footer-props="{itemsPerPageText: 'Mostrar'}"
    :search="search"
    sort-by="fecha_hora"
    sort-desc
    class=""
    @click:row="(item) => { sendDetailsMessage(item)}"
  >
    <template v-slot:header.status_code="{ header }">
      <v-icon
        small
        color="blue-grey"
        v-text="'mdi-comment-text-outline'"
      />
    </template>
    <template v-slot:item.status_code="{item}">
      <v-icon
        :color="item.status_code === '200' ? 'icono' : 'red'"
        v-text="item.status_code === '200' ? 'mdi-check-all' : 'mdi-close'"
      />
    </template>
    <template v-slot:item.co_cli="{item}">
      <span class="font-weight-bold" v-text="item.co_cli" />
    </template>
    <template v-slot:item.nombre_cliente="{item}">
      <v-list-item class="px-0" style="max-width: 19vw; width:100%">
        <v-list-item-avatar size="30">
          <v-icon
            class="blue"
            dark
          >mdi-account</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="primary--text font-weight-medium">
            {{ item.nombre_cliente }}

          </v-list-item-title>
          <v-list-item-subtitle class="font-italic" v-text="item.destinatario" />
        </v-list-item-content>
        <v-list-item-action class="d-flex align-start ma-0 pa-0">
          <v-chip
          class="ma-1 px-1 font-weight-bold"
          color="info"
          outlined
          label
          x-small
        >
          +4
        </v-chip>
        </v-list-item-action>
      </v-list-item>
    </template>
    <template v-slot:item.destinatario="{item}">
      <span class="font-italic">
        {{ item.destinatario }}
      </span>
    </template>
    <template v-slot:item.mensaje="{item}">
      <span class="d-inline-block text-sms text-truncate">
        {{ item.mensaje }}
      </span>
    </template>
    <template v-slot:item.fecha_hora="{item}">
      <div class="d-flex justify-end">
      <span class="font-weight-regular font-italic">
        {{ item.fecha_hora | dateSMS }}
      </span>
      </div>
    </template>
    <template #loading>
      <v-skeleton-loader
        type="table-tbody"
      />
    </template>
    <template v-slot:no-data>
      <div class="text-center pa-4">
        <span class="blue-grey--text font-italic">No se encontraron Mensjes Registrados</span>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import moment from 'moment'
export default {
  name: 'DataMessages',
  props:{
    search: String,
    dataSms: {
      type: Array,
      default: () => ([]),
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  data: () =>({
    headerSMS:[
      { text: '', value: 'status_code', class: 'header-data', align: 'center', width: '10px', sortable: false },
      { text: 'COD', value: 'co_cli', class: 'header-data px-0', width: '40px', align:' px-0' },
      { text: 'Destinatario', value: 'nombre_cliente', class: 'header-data', width: '17vw',align:' pr-0' },
      // { text: 'Destinatario', value: 'destinatario', class: 'header-data pr-0', width: '130px' },
      { text: 'Mensaje', value: 'mensaje', class: 'header-data', width: '17vw' },
      // { text: 'Remitente', value: 'remitente', class: 'header-data'},
      { text: 'Fecha', value: 'fecha_hora', class: 'header-data', align: 'center', width: '180px' },
    ],
    loadSMS: false,
  }),
  filters: {
    dateSMS (date) {
      moment.locale('es');
      return date ? moment(date).format('D MMM YY, hh:mm A') : '';
    },
  },
  methods: {
    sendDetailsMessage(message) {
      this.$emit('detailsMessage', message)
    },
  },
}
</script>
<style>
.text-sms{
  width: 20vw !important;
  max-width: 20vw !important;
}
</style>
